<template>
  <v-data-table
      :headers="headers"
      :items="filteredSyncs"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      :items-per-page="15"
      multi-sort
      class="elevation-1"
      :footer-props="{
      'items-per-page-options': [5, 10, 15, 30, 50, -1],
      'items-per-page-text': $t('syncsPerPage'),
    }"
  >
    <template slot="body.prepend">
      <tr>
        <td
            :style="
            $vuetify.breakpoint.name === 'xs'
              ? 'display: block; width: 100%; padding-top: 13px'
              : ''
          "
        >
          <v-btn
              v-show="connectionId"
              @click="connectionId = ''"
              small
              text
              color="primary"
              class="ma-0 pa-0"
              style="float: right"
          >{{ x }}
          </v-btn
          >
          <v-text-field
              v-model="connectionId"
              :label="$vuetify.breakpoint.name === 'xs' ? 'Connection ID' : ''"
              class="ma-0 pa-0"
              type="text"
              dense
          ></v-text-field>
        </td>
        <td
            :style="
            $vuetify.breakpoint.name === 'xs'
              ? 'display: block; width: 100%; padding-top: 13px'
              : ''
          "
        >
          <v-btn
              v-show="connectionBetween"
              @click="connectionBetween = ''"
              small
              text
              color="primary"
              class="ma-0 pa-0"
              style="float: right"
          >{{ x }}
          </v-btn
          >
          <v-text-field
              v-model="connectionBetween"
              :label="
              $vuetify.breakpoint.name === 'xs' ? 'Connection between' : ''
            "
              class="ma-0 pa-0"
              type="text"
              dense
          ></v-text-field>
        </td>
        <td
            :style="
            $vuetify.breakpoint.name === 'xs'
              ? 'display: block; width: 100%; padding-top: 13px'
              : ''
          "
        >
          <v-btn
              v-show="scheduled"
              @click="scheduled = ''"
              small
              text
              color="primary"
              class="ma-0 pa-0"
              style="float: right"
          >{{ x }}
          </v-btn
          >
          <v-menu
              ref="scheduledMenu"
              v-model="scheduledMenu"
              :close-on-content-click="false"
              :return-value.sync="scheduled"
              transition="scale-transition"
              origin="center center"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="formatScheduled"
                  :label="$vuetify.breakpoint.name === 'xs' ? 'Scheduled' : ''"
                  :append-icon="!scheduled ? 'mdi-calendar' : ''"
                  class="ma-0 pa-0"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="scheduled" scrollable show-adjacent-months>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="scheduledMenu = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.scheduledMenu.save(scheduled)"
              >
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </td>
        <td
            :style="
            $vuetify.breakpoint.name === 'xs'
              ? 'display: block; width: 100%; padding-top: 13px'
              : ''
          "
        >
          <v-btn
              v-show="type"
              @click="type = ''"
              small
              text
              color="primary"
              class="ma-0 pa-0"
              style="float: right"
          >{{ x }}
          </v-btn
          >
          <v-select
              v-model="type"
              :label="$vuetify.breakpoint.name === 'xs' ? 'Type' : ''"
              :items="[
              '',
              $t('typeOfSync.syncObjects'),
              $t('typeOfSync.timeEntries'),
            ]"
              dense
          ></v-select>
        </td>
        <td
            :style="
            $vuetify.breakpoint.name === 'xs'
              ? 'display: block; width: 100%; padding-top: 13px'
              : ''
          "
        >
          <v-btn
              v-show="origin"
              @click="origin = ''"
              small
              text
              color="primary"
              class="ma-0 pa-0"
              style="float: right"
          >{{ x }}
          </v-btn
          >
          <v-select
              v-model="origin"
              :label="$vuetify.breakpoint.name === 'xs' ? 'Origin' : ''"
              :items="[
              '',
              $t('typeOfSyncOrigin.automatic'),
              $t('typeOfSyncOrigin.manual'),
            ]"
              dense
          ></v-select>
        </td>
        <td
            :style="
            $vuetify.breakpoint.name === 'xs'
              ? 'display: block; width: 100%; padding-top: 13px'
              : ''
          "
        >
          <v-btn
              v-show="completedAt"
              @click="completedAt = ''"
              small
              text
              color="primary"
              class="ma-0 pa-0"
              style="float: right"
          >{{ x }}
          </v-btn
          >
          <v-menu
              ref="completedAtMenu"
              v-model="completedAtMenu"
              :close-on-content-click="false"
              :return-value.sync="completedAt"
              transition="scale-transition"
              origin="center center"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="formatCompletedAt"
                  :label="$vuetify.breakpoint.name === 'xs' ? 'Completed at' : ''"
                  :append-icon="!completedAt ? 'mdi-calendar' : ''"
                  class="ma-0 pa-0"
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  min-width="auto"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="completedAt"
                scrollable
                show-adjacent-months
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="completedAtMenu = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.completedAtMenu.save(completedAt)"
              >
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </td>
        <td
            :style="
            $vuetify.breakpoint.name === 'xs'
              ? 'display: block; width: 100%; padding-top: 13px'
              : ''
          "
        >
          <v-btn
              v-show="status"
              @click="status = ''"
              small
              text
              color="primary"
              class="ma-0 pa-0"
              style="float: right"
          >{{ x }}
          </v-btn
          >
          <v-select
              class="ma-0 pa-0"
              v-model="status"
              :label="$vuetify.breakpoint.name === 'xs' ? 'Status' : ''"
              :items="[
                  '',
                  $t('typeOfStatus.inProgress'),
                  $t('typeOfStatus.successful'),
                  $t('typeOfStatus.unsuccessful')
                  ]"
              dense
          ></v-select>
        </td>
        <td class="hidden-xs-only"></td>
      </tr>
    </template>
    <template v-slot:item.status="{ item }">
      <v-icon color="success" v-if="item.status === $t('typeOfStatus.successful')"> mdi-check</v-icon>
      <v-icon color="warning" v-if="item.status === $t('typeOfStatus.unsuccessful')"> mdi-alert</v-icon>
      <div v-if="item.status === $t('typeOfStatus.inProgress')">
        <v-progress-circular
            indeterminate
            color="primary"
            size="20"
            width="2"
        ></v-progress-circular>
      </div>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        {{ item.errors && item.errors.length > 0 ? item.errors : $t('logsTable.noErrorMessage') }}
      </td>
    </template>
    <template slot="no-data">
      <div>
        <span
            v-if=isDataEmpty
        >
          {{ $t("noLogsAvailable") }}
        </span>
        <v-btn
            @click="deleteAllFilters"
            v-else
            text
            color="primary"
            class="ma-0 pa-0"
        >{{ $t("deleteAllFilters") }}
        </v-btn
        >
      </div>
      <v-snackbar right top app color="error" v-model="fetchLogsError">
        {{ $t("notificationSnackbars.unableToFetchSyncLogs") }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="fetchLogsError = false">
            {{ $t("buttons.close") }}
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </v-data-table>
</template>

<script>
import {SyncType} from "@/enums/jobs/typeOfSync";
import {SyncOriginType} from "@/enums/jobs/typeOfSyncOrigin";
import {JobStatusType} from "@/enums/jobs/typeOfJobStatus";
import {getDateFormatOptions, getTimeFormatOptions} from "@/components/connections/shared";
import {getLogs} from "@/services/backendService";
import {Constants} from "@/constants/Constants";

export default {
  data() {
    return {
      expanded: [],
      singleExpand: false,
      connectionId: "",
      connectionBetween: "",
      scheduled: "",
      type: "",
      origin: "",
      completedAt: "",
      status: "",
      scheduledMenu: "",
      completedAtMenu: "",
      x: "X",
      fetchLogsError: false,
      headers: [
        {
          text: this.$t("logsTable.connectionId"),
          align: "start",
          value: "connectionId",
        },
        {
          text: this.$t("logsTable.connectionBetween"),
          value: "connectionBetween",
        },
        {
          text: this.$t("logsTable.scheduled"),
          value: "scheduled",
        },
        {text: this.$t("logsTable.type"), value: "type"},
        {text: this.$t("logsTable.origin"), value: "origin"},
        {
          text: this.$t("logsTable.completedAt"),
          value: "completedAt",
        },
        {text: this.$t("logsTable.status"), value: "status"},
        {value: "data-table-expand"},
      ],
      syncs: [],
    };
  },
  computed: {
    isDataEmpty() {
      return this.syncs.length === 0;
    },
    formatScheduled() {
      if (!this.scheduled) {
        return "";
      }
      const [year, month, day] = this.scheduled.split('-');
      const date = new Date(year, month - 1, day);
      return date.toLocaleString(undefined, getDateFormatOptions(this.$store.state.user.timeZone));
    },
    formatCompletedAt() {
      if (!this.completedAt) {
        return "";
      }
      const [year, month, day] = this.completedAt.split('-');
      const date = new Date(year, month - 1, day);
      return date.toLocaleString(undefined, getDateFormatOptions(this.$store.state.user.timeZone));
    },
    filteredSyncs() {
      var conditions = [];

      if (this.connectionId) {
        conditions.push(this.filterConnectionId);
        console.log("connectionId" + JSON.stringify(conditions))
      }

      if (this.connectionBetween) {
        conditions.push(this.filterConnectionBetween);
      }

      if (this.scheduled) {
        conditions.push(this.filterScheduled);
      }

      if (this.type) {
        conditions.push(this.filterType);
      }

      if (this.origin) {
        conditions.push(this.filterOrigin);
      }

      if (this.completedAt) {
        conditions.push(this.filterCompletedAt);
      }

      if (this.status) {
        conditions.push(this.filterStatus);
      }

      if (conditions.length > 0) {
        return this.syncs.filter((sync) => {
          return conditions.every((condition) => {
            return condition(sync);
          });
        });
      }

      return this.syncs;
    },
  },
  methods: {
    filterConnectionId(item) {
      return item.connectionId.toString().includes(this.connectionId);
    },
    filterConnectionBetween(item) {
      return item.connectionBetween.includes(this.connectionBetween);
    },
    filterScheduled(item) {
      return item.scheduled.includes(this.formatScheduled);
    },
    filterType(item) {
      if (
          (item.type === this.$t(SyncType.SYNC_OBJECTS) &&
              this.type === this.$t(SyncType.SYNC_OBJECTS)) ||
          (item.type === this.$t(SyncType.TIME_ENTRIES) &&
              this.type === this.$t(SyncType.TIME_ENTRIES))
      )
        return true;
      return false;
    },
    filterOrigin(item) {
      if (
          (item.origin === this.$t(SyncOriginType.AUTOMATIC) &&
              this.origin === this.$t(SyncOriginType.AUTOMATIC)) ||
          (item.origin === this.$t(SyncOriginType.MANUAL) &&
              this.origin === this.$t(SyncOriginType.MANUAL))
      )
        return true;
      return false;
    },
    filterCompletedAt(item) {
      return item.completedAt.includes(this.formatCompletedAt);
    },
    filterStatus(item) {
      return item.status === this.status;
    },
    deleteAllFilters() {
      this.connectionId = "";
      this.connectionBetween = "";
      this.scheduled = "";
      this.type = "";
      this.origin = "";
      this.completedAt = "";
      this.status = "";
    },
    async pollLogs() {
      await this.getLogs();
      this.pollingSetInterval = setInterval(async () => {
        await this.getLogs();
      }, Constants.POLLING_INTERVAL);
    },
    async getLogs() {
      const logs = await getLogs();
      if (!logs) {
        this.fetchLogsError = true;
      } else {
        this.processLogs(logs)
      }
    },
    processLogs(logs) {
      for (const processedLog of logs) {
        let found = false;
        for (let i = 0; i < this.syncs.length; i++) {
          if (this.syncs[i].id === processedLog._id) {
            this.syncs[i].status = this.mapSyncStatus(processedLog.status);
            this.syncs[i].completedAt = this.getDateTime(processedLog.completed),
                this.syncs[i].errors = processedLog.errors;
            found = true;
            continue;
          }
        }

        if (found) {
          continue;
        }

        const log = {
          id: processedLog._id,
          connectionId: processedLog.userConnectionId,
          connectionBetween: processedLog.connectionBetween,
          scheduled: this.getDateTime(processedLog.scheduledDate),
          type: this.mapSyncType(processedLog.type),
          origin: this.mapOrigin(processedLog.origin),
          completedAt: this.getDateTime(processedLog.completed),
          status: this.mapSyncStatus(processedLog.status),
          errors: processedLog.errors,
        };
        this.syncs.unshift(log);
      }
    },
    getDateTime(dateUnix) {
      if (!dateUnix) {
        return "";
      }
      const date = new Date(dateUnix);
      return date.toLocaleString(undefined, getTimeFormatOptions(this.$store.state.user.timeZone));
    },
    mapSyncType(syncType) {
      if (syncType === "config") {
        return this.$t(SyncType.SYNC_OBJECTS)
      } else if (syncType === "time-entries") {
        return this.$t(SyncType.TIME_ENTRIES)
      } else {
        return syncType;
      }
    },
    mapOrigin(origin) {
      if (origin === "t2t-auto") {
        return this.$t(SyncOriginType.AUTOMATIC)
      } else if (origin === "manual") {
        return this.$t(SyncOriginType.MANUAL)
      } else {
        return origin;
      }
    },
    mapSyncStatus(syncStatus) {
      if (syncStatus === "scheduled" || syncStatus === "running") {
        return this.$t("typeOfStatus.inProgress")
      } else if (syncStatus === "successful") {
        return this.$t("typeOfStatus.successful")
      } else if (syncStatus === "unsuccessful") {
        return this.$t("typeOfStatus.unsuccessful")
      } else {
        return syncStatus;
      }
    },
  },
  created() {
    this.connectionId = this.$route.params.connectionBoxId;
    this.pollLogs();
  },
  beforeDestroy() {
    clearInterval(this.pollingSetInterval);
  },
};
</script>

<style scoped></style>
