var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredSyncs,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":"","items-per-page":15,"multi-sort":"","footer-props":{
    'items-per-page-options': [5, 10, 15, 30, 50, -1],
    'items-per-page-text': _vm.$t('syncsPerPage'),
  }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function({ item }){return [(item.status === _vm.$t('typeOfStatus.successful'))?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check")]):_vm._e(),(item.status === _vm.$t('typeOfStatus.unsuccessful'))?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-alert")]):_vm._e(),(item.status === _vm.$t('typeOfStatus.inProgress'))?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"20","width":"2"}})],1):_vm._e()]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.errors && item.errors.length > 0 ? item.errors : _vm.$t('logsTable.noErrorMessage'))+" ")])]}}])},[_c('template',{slot:"body.prepend"},[_c('tr',[_c('td',{style:(_vm.$vuetify.breakpoint.name === 'xs'
            ? 'display: block; width: 100%; padding-top: 13px'
            : '')},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.connectionId),expression:"connectionId"}],staticClass:"ma-0 pa-0",staticStyle:{"float":"right"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.connectionId = ''}}},[_vm._v(_vm._s(_vm.x)+" ")]),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"label":_vm.$vuetify.breakpoint.name === 'xs' ? 'Connection ID' : '',"type":"text","dense":""},model:{value:(_vm.connectionId),callback:function ($$v) {_vm.connectionId=$$v},expression:"connectionId"}})],1),_c('td',{style:(_vm.$vuetify.breakpoint.name === 'xs'
            ? 'display: block; width: 100%; padding-top: 13px'
            : '')},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.connectionBetween),expression:"connectionBetween"}],staticClass:"ma-0 pa-0",staticStyle:{"float":"right"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.connectionBetween = ''}}},[_vm._v(_vm._s(_vm.x)+" ")]),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"label":_vm.$vuetify.breakpoint.name === 'xs' ? 'Connection between' : '',"type":"text","dense":""},model:{value:(_vm.connectionBetween),callback:function ($$v) {_vm.connectionBetween=$$v},expression:"connectionBetween"}})],1),_c('td',{style:(_vm.$vuetify.breakpoint.name === 'xs'
            ? 'display: block; width: 100%; padding-top: 13px'
            : '')},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.scheduled),expression:"scheduled"}],staticClass:"ma-0 pa-0",staticStyle:{"float":"right"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.scheduled = ''}}},[_vm._v(_vm._s(_vm.x)+" ")]),_c('v-menu',{ref:"scheduledMenu",attrs:{"close-on-content-click":false,"return-value":_vm.scheduled,"transition":"scale-transition","origin":"center center","min-width":"auto"},on:{"update:returnValue":function($event){_vm.scheduled=$event},"update:return-value":function($event){_vm.scheduled=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ma-0 pa-0",attrs:{"label":_vm.$vuetify.breakpoint.name === 'xs' ? 'Scheduled' : '',"append-icon":!_vm.scheduled ? 'mdi-calendar' : '',"dense":"","readonly":""},model:{value:(_vm.formatScheduled),callback:function ($$v) {_vm.formatScheduled=$$v},expression:"formatScheduled"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.scheduledMenu),callback:function ($$v) {_vm.scheduledMenu=$$v},expression:"scheduledMenu"}},[_c('v-date-picker',{attrs:{"scrollable":"","show-adjacent-months":""},model:{value:(_vm.scheduled),callback:function ($$v) {_vm.scheduled=$$v},expression:"scheduled"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.scheduledMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.scheduledMenu.save(_vm.scheduled)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1),_c('td',{style:(_vm.$vuetify.breakpoint.name === 'xs'
            ? 'display: block; width: 100%; padding-top: 13px'
            : '')},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.type),expression:"type"}],staticClass:"ma-0 pa-0",staticStyle:{"float":"right"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.type = ''}}},[_vm._v(_vm._s(_vm.x)+" ")]),_c('v-select',{attrs:{"label":_vm.$vuetify.breakpoint.name === 'xs' ? 'Type' : '',"items":[
            '',
            _vm.$t('typeOfSync.syncObjects'),
            _vm.$t('typeOfSync.timeEntries'),
          ],"dense":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('td',{style:(_vm.$vuetify.breakpoint.name === 'xs'
            ? 'display: block; width: 100%; padding-top: 13px'
            : '')},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.origin),expression:"origin"}],staticClass:"ma-0 pa-0",staticStyle:{"float":"right"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.origin = ''}}},[_vm._v(_vm._s(_vm.x)+" ")]),_c('v-select',{attrs:{"label":_vm.$vuetify.breakpoint.name === 'xs' ? 'Origin' : '',"items":[
            '',
            _vm.$t('typeOfSyncOrigin.automatic'),
            _vm.$t('typeOfSyncOrigin.manual'),
          ],"dense":""},model:{value:(_vm.origin),callback:function ($$v) {_vm.origin=$$v},expression:"origin"}})],1),_c('td',{style:(_vm.$vuetify.breakpoint.name === 'xs'
            ? 'display: block; width: 100%; padding-top: 13px'
            : '')},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.completedAt),expression:"completedAt"}],staticClass:"ma-0 pa-0",staticStyle:{"float":"right"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.completedAt = ''}}},[_vm._v(_vm._s(_vm.x)+" ")]),_c('v-menu',{ref:"completedAtMenu",attrs:{"close-on-content-click":false,"return-value":_vm.completedAt,"transition":"scale-transition","origin":"center center","min-width":"auto"},on:{"update:returnValue":function($event){_vm.completedAt=$event},"update:return-value":function($event){_vm.completedAt=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ma-0 pa-0",attrs:{"label":_vm.$vuetify.breakpoint.name === 'xs' ? 'Completed at' : '',"append-icon":!_vm.completedAt ? 'mdi-calendar' : '',"dense":"","readonly":"","min-width":"auto"},model:{value:(_vm.formatCompletedAt),callback:function ($$v) {_vm.formatCompletedAt=$$v},expression:"formatCompletedAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.completedAtMenu),callback:function ($$v) {_vm.completedAtMenu=$$v},expression:"completedAtMenu"}},[_c('v-date-picker',{attrs:{"scrollable":"","show-adjacent-months":""},model:{value:(_vm.completedAt),callback:function ($$v) {_vm.completedAt=$$v},expression:"completedAt"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.completedAtMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.completedAtMenu.save(_vm.completedAt)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1),_c('td',{style:(_vm.$vuetify.breakpoint.name === 'xs'
            ? 'display: block; width: 100%; padding-top: 13px'
            : '')},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.status),expression:"status"}],staticClass:"ma-0 pa-0",staticStyle:{"float":"right"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.status = ''}}},[_vm._v(_vm._s(_vm.x)+" ")]),_c('v-select',{staticClass:"ma-0 pa-0",attrs:{"label":_vm.$vuetify.breakpoint.name === 'xs' ? 'Status' : '',"items":[
                '',
                _vm.$t('typeOfStatus.inProgress'),
                _vm.$t('typeOfStatus.successful'),
                _vm.$t('typeOfStatus.unsuccessful')
                ],"dense":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('td',{staticClass:"hidden-xs-only"})])]),_c('template',{slot:"no-data"},[_c('div',[(_vm.isDataEmpty)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("noLogsAvailable"))+" ")]):_c('v-btn',{staticClass:"ma-0 pa-0",attrs:{"text":"","color":"primary"},on:{"click":_vm.deleteAllFilters}},[_vm._v(_vm._s(_vm.$t("deleteAllFilters"))+" ")])],1),_c('v-snackbar',{attrs:{"right":"","top":"","app":"","color":"error"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.fetchLogsError = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t("buttons.close"))+" ")])]}}]),model:{value:(_vm.fetchLogsError),callback:function ($$v) {_vm.fetchLogsError=$$v},expression:"fetchLogsError"}},[_vm._v(" "+_vm._s(_vm.$t("notificationSnackbars.unableToFetchSyncLogs"))+" ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }